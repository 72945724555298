import { flux } from '@/core/flux.module';
import { LIMIT_SIZE_1000 } from '@/main/app.constants';
import { SortFieldEnum, sqConditionMonitorsApi } from '@/sdk/api/ConditionMonitorsApi';
import { isConditionMonitorNotificationsEnabled } from '@/services/systemConfiguration.utilities';
import { logError } from '@/utilities/logger';
import _ from 'lodash';
import { isPresentationWorkbookMode } from '@/utilities/utilities';
import { isCanceled } from '@/utilities/http.utilities';

export async function updateConditionNotificationIds(conditionIds: string[]): Promise<void> {
  if (!isConditionMonitorNotificationsEnabled() || isPresentationWorkbookMode() || _.isEmpty(conditionIds)) {
    return;
  }

  try {
    const {
      data: { conditionMonitorItems },
    } = await sqConditionMonitorsApi.getConditionMonitors({
      conditionIds,
      sortField: SortFieldEnum.Name,
      limit: LIMIT_SIZE_1000,
    });

    flux.dispatch('TREND_SET_CONDITION_NOTIFICATION_IDS', { conditionIds, conditionMonitorItems });
  } catch (e) {
    if (!isCanceled(e)) {
      logError(_.toString(e));
    }
  }
}
